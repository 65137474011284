import { css, styled } from "solid-styled-components";
import { theme } from "const/theme";
import { JSX, Show } from "solid-js";
import Fa from "styles/fa";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const StyledInputClass = css`
    padding: 0.25em 0.5em;
    border-radius: 2px;
    border: 1px solid ${theme.colors.bright};
    outline: none;

    &:focus {
        outline: ${theme.colors.dark} solid 2px;
    }

    &.error {
        border: 1px solid ${theme.colors.error};
    }
`;

const StyledInput = (props: JSX.InputHTMLAttributes<HTMLInputElement>) => {
    return <input placeholder={"..."} {...props} class={StyledInputClass}></input>;
};

export default StyledInput;
