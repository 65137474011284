import { theme } from "const/theme";
import { showCookieDetails } from "cookieConsent/state";
import { FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import { P2 } from "styles/text";
import getText from "text/shared/footer";
import { getParentDomain } from "utils";
import { createTextObject } from "utils/content";

const Component = () => {
    const text = createTextObject(getText);
    return (
        <StyledColumns fullWidth justifyContent="center" style={{ "border-top": `1px solid ${theme.colors.bright}` }}>
            <StyledColumns style={{ "max-width": "600px" }} fullWidth padding gap justifyContent="space-between">
                <a href={getParentDomain() + "/privacy"}>
                    <P2>{text()?.privacy}</P2>
                </a>

                <a href={getParentDomain() + "/terms"}>
                    <P2>{text()?.terms}</P2>
                </a>

                <a href={getParentDomain() + "/imprint"}>
                    <P2>{text()?.imprint}</P2>
                </a>

                <P2 color="main" clickable onClick={() => showCookieDetails()}>
                    {text()?.cookie_settings}
                </P2>
            </StyledColumns>
        </StyledColumns>
    );
};

export default Component;
