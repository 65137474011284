import { createEffect, createResource, createSignal, Show, Suspense } from "solid-js";
import CookieConsent from "cookieConsent";
import { RoutePaths } from "./routes";
import { GlobalStyles } from "styles/global";
import { MetaProvider, Style } from "@solidjs/meta";
import { accountState } from "./backend/account";
import { StyledColumns } from "styles/layout";
import Fa from "styles/fa";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Component = () => {
    return (
        <MetaProvider>
            <GlobalStyles></GlobalStyles>

            <Suspense
                fallback={
                    <StyledColumns fullHeight flex="1 1 0px" alignItems="center" justifyContent="center" alignContent="center">
                        <Fa icon={faSpinner} spin></Fa>
                    </StyledColumns>
                }
            >
                <RoutePaths></RoutePaths>
            </Suspense>
        </MetaProvider>
    );
};

export default Component;
