import { Navigate, Route, Router } from "@solidjs/router";
import { Show, lazy } from "solid-js";
import Login from "app/components/auth/login";
import Navigation from "app/components/navigation";
import { accountState } from "./backend/account";
import { langFilter } from "utils/router";

const VerifyEmailChange = lazy(() => import("app/components/auth/verifyEmailChange"));
export const RoutePaths = () => {
    return (
        <Router>
                <Route path={"/:lang?/*"} matchFilters={langFilter} component={Navigation}>
                    <Route path={"/verify/password/change/:token"} component={lazy(() => import("app/components/auth/verifyPasswordChange"))}></Route>
                    <Route path={"/verify/password/reset/:token"} component={lazy(() => import("app/components/auth/verifyPasswordReset"))}></Route>
                    <Route path={"/verify/email/:token"} component={lazy(() => import("app/components/auth/verifyEmail"))}></Route>
                    <Route path={"/verify/email/change/old/:token"} component={() => <VerifyEmailChange></VerifyEmailChange>}></Route>
                    <Route path={"/verify/email/change/new/:token"} component={() => <VerifyEmailChange new></VerifyEmailChange>}></Route>

                    <Show
                        when={accountState.data() == undefined}
                        fallback={
                            <>
                                <Route path={"/account"} component={lazy(() => import("app/components/account"))}></Route>
                                <Show
                                    when={accountState.data()?.account_type == "Company"}
                                    fallback={
                                        <>
                                            <Route path={"/talents"} component={lazy(() => import("app/components/talents/student"))}></Route>
                                        </>
                                    }
                                >
                                    <Route path={"/talents"} component={lazy(() => import("app/components/talents/company"))}></Route>
                                </Show>
                                <Route path={"/"} component={lazy(() => import("app/components/home"))}></Route>
                                <Route path={"/*"} component={() => <Navigate href={"/"}></Navigate>}></Route>
                            </>
                        }
                    >
                        <Route path={"/register"} component={lazy(() => import("app/components/auth/register"))}></Route>
                        <Route path={"/*"} component={Login}></Route>
                    </Show>
                </Route>
        </Router>
    );
};