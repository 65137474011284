import { css, styled } from "solid-styled-components";
import { theme } from "const/theme";
import { JSX, Show, createSignal } from "solid-js";
import Fa from "styles/fa";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { CleanButton } from "./clean";

const StyledInputClass = css`
    padding: 0.25em 0.5em;
    padding-right: 35px;
    border-radius: 2px;
    border: 1px solid ${theme.colors.bright};
    outline: none;

    &:focus {
        outline: ${theme.colors.second} solid 2px;
    }

    &.error {
        border: 1px solid ${theme.colors.error};
    }
`;

const StyledInput = (props: JSX.InputHTMLAttributes<HTMLInputElement>) => {
    const [type, setType] = createSignal<"password" | "text">("password");
    return (
        <div style={{ position: "relative" }}>
            <input style={{ width: "100%" }} placeholder={"..."} {...props} type={type()} class={StyledInputClass}></input>
            <CleanButton
                style={{ position: "absolute", top: "50%", right: "3px", transform: "translateX(-50%) translateY(-50%)" }}
                onClick={() => (type() == "password" ? setType("text") : setType("password"))}
            >
                <Fa icon={type() == "password" ? faEye : faEyeSlash}></Fa>
            </CleanButton>
        </div>
    );
};

export default StyledInput;
