import { Setter, createSignal } from "solid-js";
import { createTextObject } from "utils/content";
import getText from "text/app/auth/login";
import { FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import { CleanButton, StyledButton, StyledDialog, StyledInput, StyledPassword } from "styles/interface";
import { P1, P2 } from "styles/text";
import { createPasswordReset, login } from "app/backend/account";
import { LangA } from "utils/router";
import { email_regex } from "utils";
import { Title } from "@solidjs/meta";
import { StyledDialogElement } from "styles/interface/dialog";

const Component = () => {
    const text = createTextObject(getText);
    const [credentials, setCredentials] = createSignal({ password: "", identifier: "" });
    const [resetMail, setResetMail] = createSignal("");
    const [dialog, setDialog] = createSignal<StyledDialogElement>();
    const [error, setError] = createSignal(false);

    return (
        <>
            <Title>{text()?.page_title}</Title>
            <StyledDialog ref={setDialog} title={text()?.password_forgot}>
                <StyledRows padding gap>
                    <StyledRows gap="small">
                        <P1>{text()?.forgot.email_label}</P1>
                        <StyledInput onInput={(ev) => setResetMail(ev.target.value)}></StyledInput>
                    </StyledRows>
                    <P1>{text()?.forgot.note}</P1>
                    <StyledButton
                        disabled={email_regex.exec(resetMail()) == null}
                        onClick={() => {
                            createPasswordReset(resetMail());
                            setResetMail("");
                            dialog()?.close();
                        }}
                    >
                        {text()?.forgot.reset_button}
                    </StyledButton>
                </StyledRows>
            </StyledDialog>
            <StyledColumns justifyContent="center" padding flex="1 1 auto">
                <StyledRows style={{ "max-width": "600px" }} fullWidth padding gap="big" alignItems="stretch" justifyContent="center">
                    <StyledRows gap="small">
                        <P1>{text()?.username_label}</P1>
                        <StyledInput
                            type="email"
                            classList={{ error: error() }}
                            onInput={(ev) => {
                                setError(false);
                                setCredentials((prev) => {
                                    prev.identifier = ev.target.value;
                                    return structuredClone(prev);
                                });
                            }}
                        ></StyledInput>
                        <P1 color="error" style={{ display: error() ? "initial" : "none" }}>
                            {text()?.error}
                        </P1>
                    </StyledRows>
                    <StyledRows gap="small">
                        <StyledColumns alignItems="center">
                            <P1>{text()?.password_label}</P1>
                            <FlexAuto></FlexAuto>
                            <CleanButton onClick={() => dialog()?.showModal()}>
                                <P2 clickable color="main">
                                    {text()?.password_forgot}
                                </P2>
                            </CleanButton>
                        </StyledColumns>
                        <StyledPassword
                            classList={{ error: error() }}
                            onInput={(ev) => {
                                setError(false);
                                setCredentials((prev) => {
                                    prev.password = ev.target.value;
                                    return structuredClone(prev);
                                });
                            }}
                        ></StyledPassword>
                    </StyledRows>
                    <StyledButton
                        disabled={credentials().identifier == "" || credentials().password == "" || error()}
                        onClick={async () => {
                            setError(!(await login(credentials())));
                        }}
                    >
                        {text()?.login_button}
                    </StyledButton>
                    <P1>{text()?.no_account}</P1>

                    <LangA href="/register">
                        <StyledButton style={{ width: "100%" }}>{text()?.register_button}</StyledButton>
                    </LangA>
                </StyledRows>
            </StyledColumns>
        </>
    );
};

export default Component;
