import { useNavigate } from "@solidjs/router";
import { accountState, logout } from "app/backend/account";
import { createEffect, createSignal, JSX, onCleanup, onMount, Show, Suspense } from "solid-js";
import { BaseDiv, FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import Fa from "styles/fa";
import { faBars, faBell, faClock, faHome, faPeopleGroup, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import { theme } from "const/theme";
import { FloatingContainer } from "styles/floating";
import { P1, P2 } from "styles/text";
import { css } from "solid-styled-components";
import { createTextObject } from "utils/content";
import getText from "text/app/navigation";
import { getParentDomain } from "utils";
import { navigationTitle } from "app/states/navigation";
import { LangA } from "utils/router";
import { showCookieDetails } from "cookieConsent/state";
import CookieConsent from "cookieConsent";
import Footer from "app/components/footer";
import { CleanButton, CleanA } from "styles/interface";
import { DesktopHide, MobileHide } from "styles/display";
import { isServer } from "solid-js/web";

const IconClass = css({
    width: "30px !important",
    height: "30px !important",
    margin: "0px 10px",
});

const Component = (props: {children?: JSX.Element}) => {
    const text = createTextObject(getText);
    const [expanded, setExpanded] = createSignal(false);
    const [mobileNav, setMobileNav] = createSignal<HTMLDivElement | undefined>();
    const [width, setWidth] = createSignal("50px");

    let resizeListener = () => {
        if (window.innerWidth > 720 && expanded()) setExpanded(false);
    };

    let clickHandler = (ev: MouseEvent) => {
        //@ts-ignore
        if (expanded() == true && ev.target != null && !mobileNav()?.contains(ev.target)) {
            setExpanded(false);
        }
    };

    onMount(() => {
        window.addEventListener("resize", resizeListener);
        window.addEventListener("click", clickHandler);
    });

    onCleanup(() => {
        window.removeEventListener("resize", resizeListener);
        window.removeEventListener("click", clickHandler);
    });

    return (
        <>
            <Show
                when={accountState.data() != undefined}
                fallback={
                    <>
                        {props.children}
                        <Footer></Footer>
                    </>
                }
            >
                <StyledRows style={{ height: "100vh", overflow: "hidden" }}>
                    <StyledRows style={{ position: "relative" }}>
                        <StyledColumns
                            style={{ "border-bottom": `1px solid ${theme.colors.bright}` }}
                            alignItems="center"
                            alignContent="center"
                            fullWidth
                            padding
                            gap="big"
                        >
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpanded(!expanded());
                                }}
                                style={{ cursor: "pointer" }}
                                classList={{ [DesktopHide]: true }}
                            >
                                <Fa color={theme.colors.main} icon={faBars} scale={1.5}></Fa>
                            </div>

                            <LangA href="/" style={{ cursor: "pointer" }}>
                                <StyledColumns gap>
                                    <img src="/assets/img/logo.svg"  style={{ height: "20px", display: "block" }}></img>
                                    <img src="/assets/img/word-logo.svg" style={{ height: "20px", display: "block" }}></img>
                                </StyledColumns>
                            </LangA>

                            <FlexAuto></FlexAuto>

                            <FloatingContainer
                                floatingElement={
                                    <StyledRows gap padding>
                                        <Show when={accountState.data()?.email_verified == false}>
                                            <P1>{text()?.email_verify_note}</P1>
                                        </Show>
                                    </StyledRows>
                                }
                            >
                                <div style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "-7px",
                                            left: "-7px",
                                            height: "14px",
                                            width: "14px",
                                            display: accountState.data()?.email_verified == false ? "initial" : "none",
                                            "background-color": theme.colors.error,
                                            "border-radius": "200px",
                                        }}
                                    >
                                        <P2 textAlign="center" color="white">
                                            1
                                        </P2>
                                    </div>
                                    <Fa icon={faBell} color={theme.colors.main} scale={1.5}></Fa>
                                </div>
                            </FloatingContainer>

                            <FloatingContainer
                                options={{ interactive: true, trigger: "click" }}
                                floatingElement={
                                    <StyledRows style={{ padding: "3px" }} gap="small">
                                        <CleanA padding href="/account">
                                            <P1 color="black">{text()?.account_label}</P1>
                                        </CleanA>
                                        <FloatingContainer
                                            options={{ interactive: true, placement: "left-start" }}
                                            floatingElement={
                                                <StyledRows style={{ padding: "3px", width: "200px" }} gap="small">
                                                    <CleanA padding href={getParentDomain() + "/privacy"}>
                                                        <P1 color="black">{text()?.info.privacy_label}</P1>
                                                    </CleanA>
                                                    <CleanA padding href={getParentDomain() + "/terms"}>
                                                        <P1 color="black">{text()?.info.terms_label}</P1>
                                                    </CleanA>
                                                    <CleanA padding href={getParentDomain() + "/imprint"}>
                                                        <P1 color="black">{text()?.info.imprint_label}</P1>
                                                    </CleanA>
                                                    <CleanButton padding onClick={() => showCookieDetails()}>
                                                        <P1 color="black">{text()?.info.cookie_settings}</P1>
                                                    </CleanButton>
                                                </StyledRows>
                                            }
                                        >
                                            <CleanButton padding>
                                                <P1>{text()?.info.label}</P1>
                                            </CleanButton>
                                        </FloatingContainer>
                                        <CleanButton padding onClick={() => logout()}>
                                            <P1>{text()?.logout_label}</P1>
                                        </CleanButton>
                                    </StyledRows>
                                }
                            >
                                <Fa icon={faUser} scale={1.5} color={theme.colors.main}></Fa>
                            </FloatingContainer>
                        </StyledColumns>

                        {/** Mobile Navigation */}
                        <BaseDiv
                            ref={setMobileNav}
                            padding
                            style={{
                                "border-bottom": `1px solid ${theme.colors.bright}`,
                                display: expanded() ? "initial" : "none",
                                position: "absolute",
                                top: "100%",
                                "background-color": theme.colors.white,
                                width: "100%",
                                "z-index": 10,
                            }}
                            onClick={() => setExpanded(!expanded())}
                        >
                            <StyledRows gap="big">
                                <CleanA href="/">
                                    <StyledColumns style={{ "overflow-x": "hidden", width: "200px" }} alignItems="center">
                                        <Fa icon={faHome} classList={{ [IconClass]: true }} color={theme.colors.main}></Fa>
                                        <P1 color="black">{text()?.nav.home_label}</P1>
                                    </StyledColumns>
                                </CleanA>
                                <CleanA href="/talents">
                                    <StyledColumns style={{ "overflow-x": "hidden", width: "200px" }} alignItems="center">
                                        <Fa icon={faPeopleGroup} classList={{ [IconClass]: true }} color={theme.colors.main}></Fa>
                                        <P1 color="black">{accountState.data()?.account_type == "Student" ? text()?.nav.talents_student_label : text()?.nav.talents_label}</P1>
                                    </StyledColumns>
                                </CleanA>
                            </StyledRows>
                        </BaseDiv>
                    </StyledRows>

                    <StyledColumns flex="1 1 0px" style={{ overflow: "hidden", position: "relative" }}>
                        {/** Desktop Navigation */}
                        <StyledRows
                            classList={{ [MobileHide]: true }}
                            style={{
                                overflow: "auto",
                                "border-right": `1px solid ${theme.colors.bright}`,
                                width: width(),
                                "overflow-x": "hidden",
                                top: 0,
                                left: 0,
                                position: "absolute",
                                "min-height": "100%",
                                transition: "all 0.3s",
                            }}
                            onMouseEnter={() => {
                                setWidth("150px");
                            }}
                            onMouseLeave={() => {
                                setWidth("50px");
                            }}
                            paddingTB
                            gap="big"
                        >
                            <CleanA href="/">
                                <StyledColumns style={{ "overflow-x": "hidden", width: "200px" }} alignItems="center">
                                    <Fa icon={faHome} classList={{ [IconClass]: true }} color={theme.colors.main}></Fa>
                                    <P1 color="black">{text()?.nav.home_label}</P1>
                                </StyledColumns>
                            </CleanA>
                            <CleanA href="/talents">
                                <StyledColumns style={{ "overflow-x": "hidden", width: "200px" }} alignItems="center">
                                    <Fa icon={faPeopleGroup} classList={{ [IconClass]: true }} color={theme.colors.main}></Fa>
                                    <P1 color="black">{accountState.data()?.account_type == "Student" ? text()?.nav.talents_student_label : text()?.nav.talents_label}</P1>
                                </StyledColumns>
                            </CleanA>
                        </StyledRows>
                        <Suspense
                            fallback={
                                <StyledColumns
                                    fullHeight
                                    flex="1 1 0px"
                                    alignItems="center"
                                    justifyContent="center"
                                    alignContent="center"
                                    style={{ "padding-right": "50px" }}
                                >
                                    <Fa icon={faSpinner} spin></Fa>
                                </StyledColumns>
                            }
                        >
                            <StyledRows flex="1 1 0px" style={{ overflow: "auto", padding: "20px 0px" }} fullWidth>
                                {props.children}
                            </StyledRows>
                        </Suspense>
                    </StyledColumns>
                </StyledRows>
            </Show>
            <CookieConsent></CookieConsent>
        </>
    );
};

export default Component;
