import axios from "axios";
import { Languages } from "const";
import { createResource } from "solid-js";
import { email_regex } from "utils";

const AccountTypeValues = ["Student", "Company"] as const;
type AccountType = (typeof AccountTypeValues)[number];

interface Account {
    id: string;
    password: string;
    email: string;
    first_name: string;
    last_name: string;
    email_verified: boolean;
    account_type: AccountType;
    language: Languages | String;
}

interface LoginAccount {
    identifier: string;
    password: string;
}

export interface RegisterAccount {
    email?: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    account_type?: AccountType;
    language?: Languages | String;
}

export function checkRegisterAccount(account: RegisterAccount) {
    if (account.email && account.first_name && account.last_name && account.password && account.account_type) {
        if (email_regex.exec(account.email) != null) return false;
    }
    return true;
}

export async function logout() {
    return await axios
        .get("/api/ms_main/account/logout")
        .then(() => {
            accountState.mutate(undefined);
            return true;
        })
        .catch(() => {
            return false;
        });
}

export async function login(credentials: LoginAccount) {
    return await axios
        .post("/api/ms_main/account/login", credentials)
        .then((resp) => {
            accountState.mutate(structuredClone(resp.data));
            return true;
        })
        .catch(() => {
            return false;
        });
}

export async function registerAccount(account: RegisterAccount) {
    let new_account: Account | string = await axios
        .post("/api/ms_main/account/register", account)
        .then((resp) => resp.data)
        .catch((error) => {
            if (error.response) return error.response.data;
        });

    if (typeof new_account != "string") {
        accountState.mutate(new_account);
        return false;
    }

    if (new_account.includes("account_account_name_key")) {
        return "account_name";
    }

    if (new_account.includes("account_email_key")) {
        return "email";
    }

    return true;
}

export const accountState = (() => {
    const [data, { mutate, refetch }] = createResource(async () => {
        let account: Account | undefined = await axios
            .get("/api/ms_main/account/get")
            .then((res) => res.data)
            .catch(() => undefined);
        return account;
    });
    return { data, mutate, refetch };
})();

export async function changeEmail(email: string, password: string) {
    return await axios
        .post("/api/ms_main/account/email/change", { email, password })
        .then(() => true)
        .catch(() => false);
}

export async function changePassword(new_password: string, password: string) {
    return await axios
        .post("/api/ms_main/account/password/change", { new_password, password })
        .then(() => true)
        .catch(() => false);
}

export async function updateAccount(first_name: string, last_name: string) {
    return await axios
        .post("/api/ms_main/account/update", { first_name, last_name })
        .then(() => {
            accountState.mutate((prev) => {
                if (prev) {
                    prev.first_name == first_name;
                    prev.last_name == last_name;
                }
                return structuredClone(prev);
            });
        })
        .catch(() => false);
}

export async function deleteAccount(password: string) {
    return await axios
        .post("/api/ms_main/account/delete", { password })
        .then(() => {
            accountState.mutate(undefined);
        })
        .catch(() => false);
}

export async function verifyEmail(token: string) {
    return await axios
        .get("/api/ms_main/account/verify/email/" + token)
        .then(() => {
            accountState.mutate((prev) => {
                if (prev) {
                    prev.email_verified = true;
                }
                return structuredClone(prev);
            });
            return true;
        })
        .catch(() => false);
}

export async function verifyEmailChange(token: string, old: boolean): Promise<"changed" | boolean> {
    return await axios
        .post("/api/ms_main/account/email/change/verify/" + token, { old })
        .then((resp) => {
            if (resp.data === true) {
                accountState.refetch();
                return "changed";
            }
            return true;
        })
        .catch(() => false);
}

export async function verifyPasswordChange(token: string) {
    return await axios
        .get("/api/ms_main/account/verify/password/change/" + token)
        .then(() => {
            return true;
        })
        .catch(() => false);
}

export async function verifyPasswordReset(token: string, password: string) {
    return await axios
        .post("/api/ms_main/account/verify/password/reset/" + token, { password })
        .then(() => {
            return true;
        })
        .catch(() => false);
}

export async function createPasswordReset(email: string) {
    return await axios
        .post("/api/ms_main/account/password/reset", { email })
        .then(() => {
            return true;
        })
        .catch(() => false);
}
