import { theme } from "const/theme";
import { css } from "solid-styled-components";

export const DesktopHide = css({
    [`@media (min-width: 721px)`]: {
        display: "none !important"
    },
});

export const MobileHide = css({
    [`@media (max-width: ${theme.spacing.page.mobile})`]: {
        display: "none !important"
    },
});