import { theme } from "const/theme";
import { createGlobalStyles } from "solid-styled-components";

export const GlobalStyles = () => {
    const Styles = createGlobalStyles({
        "html, body": {
            background: theme.colors.white,
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "column",
            minHeight: "100vh",
        },
        "h1, h2, h3, h4": {
            color: theme.colors.main,
            fontFamily: "WorkSans",
            fontWeight: "bold",
        },
        strong: {
            fontWeight: "bold",
        },
        h1: {
            fontSize: theme.fonts.size.biggest,
        },
        h2: {
            fontSize: theme.fonts.size.bigger,
        },
        h3: {
            fontSize: theme.fonts.size.big,
        },
        h4: {
            fontSize: theme.fonts.size.normal,
        },
        "*": {
            wordBreak: "break-word",
            appearance: "none",
            boxSizing: "border-box",
            padding: "0px",
            margin: "0px",
            color: theme.colors.black,
            fontWeight: 400,
            fontFamily: "WorkSans",
            fontSize: theme.fonts.size.normal,
        },
        "a > *, a": {
            transition: "all 0.2s ease-out",
            color: theme.colors.main,
            textDecoration: "none",
        },
        "a:hover > *, a:hover": {
            color: theme.colors.mainLight,
        },
        '.tippy-box[data-animation="fade"][data-state="hidden"]': {
            opacity: "0",
        },
        "[data-tippy-root]": {
            maxWidth: "calc(100vw - 10px)",
        },
        '.tippy-box[data-placement^="top"] > .tippy-arrow': {
            bottom: 0,
        },
        '.tippy-box[data-placement^="top"] > .tippy-arrow:before': {
            bottom: "-7px",
            left: 0,
            borderWidth: "8px 8px 0",
            borderTopColor: "initial",
            transformOrigin: "center top",
        },
        '.tippy-box[data-placement^="bottom"] > .tippy-arrow': {
            top: 0,
        },
        '.tippy-box[data-placement^="bottom"] > .tippy-arrow:before': {
            bottom: "-7px",
            left: 0,
            borderWidth: "0 8px 8px",
            borderBottomColor: "initial",
            transformOrigin: "center bottom",
        },
        '.tippy-box[data-placement^="left"] > .tippy-arrow': {
            right: 0,
        },
        '.tippy-box[data-placement^="left"] > .tippy-arrow:before ': {
            right: "-7px",
            borderWidth: "8px 0 8px 8px",
            borderBottomColor: "initial",
            transformOrigin: "center left",
        },
        '.tippy-box[data-placement^="right"] > .tippy-arrow': {
            left: 0,
        },
        '.tippy-box[data-placement^="right"] > .tippy-arrow:before': {
            left: "-7px",
            borderWidth: "8px 8px 8px 0",
            borderBottomColor: "initial",
            transformOrigin: "center right",
        },
        '.tippy-box[data-inertia][data-state="visible"]': {
            transitionTimingFunction: "cubic-bezier(0.54, 1.5, 0.38, 1.11)",
        },
        ".tippy-arrow": {
            width: "16px",
            height: "16px",
            color: "white",
        },
        ".tippy-arrow:before": {
            content: "",
            position: "absolute",
            borderColor: "transparent",
            borderStyle: "solid",
        },
    });
    return <Styles />;
};
